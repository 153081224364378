.overview-data-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.over-view-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.over-view-data:not(:last-child) {
    border-right: 1px solid var(--p-color-border);
}

.overview-des p {
    font-style: italic;
    font-size: 12px;
    color: var(--p-color-input-border-hover);
    border-top: 1px solid var(--p-color-border);
}