.container-shop{
    margin-top: 42px;
    border-radius: 12px;
    background-color: white;
    padding: 40px 16px 8px 16px;
    display: flex;
}
.content {
    width: 65%;
    padding-left: 37px;
}
.content-title{
    font-family: SF Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 590;
    line-height: 20px;
    width: 65%;
}
.text-content {
    color:#303030;
    font-family: SF Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.content-des{
    border-bottom: 1px solid #EBEBEB;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 52%;
}
.image{
    width: 60%;
    display: flex;
    justify-content: end;
}
.image img{
    width: 100%;
    cursor: pointer;
}