@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url('./assets//font/SFProDisplay-Regular.ttf') format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url('./assets/font/SFProText-Regular.ttf') format("opentype");
}

@font-face {
  font-family: "SF Pro";
  src: local("SF Pro"), url('./assets/font/SF-Pro.ttf') format("opentype");
}

body {
  margin: 0;
  font-family: "SF Pro" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --p-border-radius-0: 0rem;
  --p-border-radius-050: 0.125rem;
  --p-border-radius-100: 0.25rem;
  --p-border-radius-150: 0.375rem;
  --p-border-radius-200: 0.5rem;
  --p-border-radius-300: 0.75rem;
  --p-border-radius-400: 1rem;
  --p-border-radius-500: 1.25rem;
  --p-border-radius-750: 1.875rem;
  --p-border-radius-full: 624.9375rem;
  --p-border-width-0165: 0.04125rem;
  --p-border-width-025: 0.0625rem;
  --p-border-width-050: 0.125rem;
  --p-border-width-100: 0.25rem;
  --p-breakpoints-xs: 0rem;
  --p-breakpoints-sm: 30.625rem;
  --p-breakpoints-md: 48rem;
  --p-breakpoints-lg: 65rem;
  --p-breakpoints-xl: 90rem;
  --p-color-bg: rgba(241, 241, 241, 1);
  --p-color-bg-inverse: rgba(26, 26, 26, 1);
  --p-color-bg-surface: rgba(255, 255, 255, 1);
  --p-color-bg-surface-hover: rgba(247, 247, 247, 1);
  --p-color-bg-surface-active: rgba(243, 243, 243, 1);
  --p-color-bg-surface-selected: rgba(241, 241, 241, 1);
  --p-color-bg-surface-disabled: rgba(0, 0, 0, 0.05);
  --p-color-bg-surface-secondary: rgba(247, 247, 247, 1);
  --p-color-bg-surface-secondary-hover: rgba(241, 241, 241, 1);
  --p-color-bg-surface-secondary-active: rgba(235, 235, 235, 1);
  --p-color-bg-surface-secondary-selected: rgba(235, 235, 235, 1);
  --p-color-bg-surface-tertiary: rgba(243, 243, 243, 1);
  --p-color-bg-surface-tertiary-hover: rgba(235, 235, 235, 1);
  --p-color-bg-surface-tertiary-active: rgba(227, 227, 227, 1);
  --p-color-bg-surface-brand: rgba(227, 227, 227, 1);
  --p-color-bg-surface-brand-hover: rgba(235, 235, 235, 1);
  --p-color-bg-surface-brand-active: rgba(241, 241, 241, 1);
  --p-color-bg-surface-brand-selected: rgba(241, 241, 241, 1);
  --p-color-bg-surface-info: rgba(234, 244, 255, 1);
  --p-color-bg-surface-info-hover: rgba(224, 240, 255, 1);
  --p-color-bg-surface-info-active: rgba(202, 230, 255, 1);
  --p-color-bg-surface-success: rgba(205, 254, 225, 1);
  --p-color-bg-surface-success-hover: rgba(180, 254, 210, 1);
  --p-color-bg-surface-success-active: rgba(146, 254, 194, 1);
  --p-color-bg-surface-caution: rgba(255, 248, 219, 1);
  --p-color-bg-surface-caution-hover: rgba(255, 244, 191, 1);
  --p-color-bg-surface-caution-active: rgba(255, 239, 157, 1);
  --p-color-bg-surface-warning: rgba(255, 241, 227, 1);
  --p-color-bg-surface-warning-hover: rgba(255, 235, 213, 1);
  --p-color-bg-surface-warning-active: rgba(255, 228, 198, 1);
  --p-color-bg-surface-critical: rgba(254, 233, 232, 1);
  --p-color-bg-surface-critical-hover: rgba(254, 226, 225, 1);
  --p-color-bg-surface-critical-active: rgba(254, 218, 217, 1);
  --p-color-bg-surface-emphasis: rgba(240, 242, 255, 1);
  --p-color-bg-surface-emphasis-hover: rgba(234, 237, 255, 1);
  --p-color-bg-surface-emphasis-active: rgba(226, 231, 255, 1);
  --p-color-bg-surface-magic: rgba(248, 247, 255, 1);
  --p-color-bg-surface-magic-hover: rgba(243, 241, 255, 1);
  --p-color-bg-surface-magic-active: rgba(233, 229, 255, 1);
  --p-color-bg-surface-inverse: rgba(48, 48, 48, 1);
  --p-color-bg-surface-transparent: rgba(0, 0, 0, 0);
  --p-color-bg-fill: rgba(255, 255, 255, 1);
  --p-color-bg-fill-hover: rgba(250, 250, 250, 1);
  --p-color-bg-fill-active: rgba(247, 247, 247, 1);
  --p-color-bg-fill-selected: rgba(204, 204, 204, 1);
  --p-color-bg-fill-disabled: rgba(0, 0, 0, 0.05);
  --p-color-bg-fill-secondary: rgba(241, 241, 241, 1);
  --p-color-bg-fill-secondary-hover: rgba(235, 235, 235, 1);
  --p-color-bg-fill-secondary-active: rgba(227, 227, 227, 1);
  --p-color-bg-fill-tertiary: rgba(227, 227, 227, 1);
  --p-color-bg-fill-tertiary-hover: rgba(212, 212, 212, 1);
  --p-color-bg-fill-tertiary-active: rgba(204, 204, 204, 1);
  --p-color-bg-fill-brand: rgba(48, 48, 48, 1);
  --p-color-bg-fill-brand-hover: rgba(26, 26, 26, 1);
  --p-color-bg-fill-brand-active: rgba(26, 26, 26, 1);
  --p-color-bg-fill-brand-selected: rgba(48, 48, 48, 1);
  --p-color-bg-fill-brand-disabled: rgba(0, 0, 0, 0.17);
  --p-color-bg-fill-info: rgba(145, 208, 255, 1);
  --p-color-bg-fill-info-hover: rgba(81, 192, 255, 1);
  --p-color-bg-fill-info-active: rgba(0, 148, 213, 1);
  --p-color-bg-fill-info-secondary: rgba(224, 240, 255, 1);
  --p-color-bg-fill-success: rgba(41, 132, 90, 1);
  --p-color-bg-fill-success-hover: rgba(19, 111, 69, 1);
  --p-color-bg-fill-success-active: rgba(12, 81, 50, 1);
  --p-color-bg-fill-success-secondary: rgba(205, 254, 225, 1);
  --p-color-bg-fill-warning: rgba(255, 184, 0, 1);
  --p-color-bg-fill-warning-hover: rgba(229, 165, 0, 1);
  --p-color-bg-fill-warning-active: rgba(178, 132, 0, 1);
  --p-color-bg-fill-warning-secondary: rgba(255, 214, 164, 1);
  --p-color-bg-fill-caution: rgba(255, 230, 0, 1);
  --p-color-bg-fill-caution-hover: rgba(234, 211, 0, 1);
  --p-color-bg-fill-caution-active: rgba(225, 203, 0, 1);
  --p-color-bg-fill-caution-secondary: rgba(255, 239, 157, 1);
  --p-color-bg-fill-critical: rgba(229, 28, 0, 1);
  --p-color-bg-fill-critical-hover: rgba(181, 38, 11, 1);
  --p-color-bg-fill-critical-active: rgba(142, 31, 11, 1);
  --p-color-bg-fill-critical-selected: rgba(142, 31, 11, 1);
  --p-color-bg-fill-critical-secondary: rgba(254, 218, 217, 1);
  --p-color-bg-fill-emphasis: rgba(0, 91, 211, 1);
  --p-color-bg-fill-emphasis-hover: rgba(0, 66, 153, 1);
  --p-color-bg-fill-emphasis-active: rgba(0, 46, 106, 1);
  --p-color-bg-fill-magic: rgba(128, 81, 255, 1);
  --p-color-bg-fill-magic-secondary: rgba(228, 222, 255, 1);
  --p-color-bg-fill-magic-secondary-hover: rgba(223, 217, 255, 1);
  --p-color-bg-fill-magic-secondary-active: rgba(212, 204, 255, 1);
  --p-color-bg-fill-inverse: rgba(48, 48, 48, 1);
  --p-color-bg-fill-inverse-hover: rgba(74, 74, 74, 1);
  --p-color-bg-fill-inverse-active: rgba(97, 97, 97, 1);
  --p-color-bg-fill-transparent: rgba(0, 0, 0, 0.02);
  --p-color-bg-fill-transparent-hover: rgba(0, 0, 0, 0.05);
  --p-color-bg-fill-transparent-active: rgba(0, 0, 0, 0.08);
  --p-color-bg-fill-transparent-selected: rgba(0, 0, 0, 0.08);
  --p-color-bg-fill-transparent-secondary: rgba(0, 0, 0, 0.06);
  --p-color-bg-fill-transparent-secondary-hover: rgba(0, 0, 0, 0.08);
  --p-color-bg-fill-transparent-secondary-active: rgba(0, 0, 0, 0.11);
  --p-color-text: rgba(48, 48, 48, 1);
  --p-color-text-secondary: rgba(97, 97, 97, 1);
  --p-color-text-disabled: rgba(181, 181, 181, 1);
  --p-color-text-link: rgba(0, 91, 211, 1);
  --p-color-text-link-hover: rgba(0, 66, 153, 1);
  --p-color-text-link-active: rgba(0, 46, 106, 1);
  --p-color-text-brand: rgba(74, 74, 74, 1);
  --p-color-text-brand-hover: rgba(48, 48, 48, 1);
  --p-color-text-brand-on-bg-fill: rgba(255, 255, 255, 1);
  --p-color-text-brand-on-bg-fill-hover: rgba(227, 227, 227, 1);
  --p-color-text-brand-on-bg-fill-active: rgba(204, 204, 204, 1);
  --p-color-text-brand-on-bg-fill-disabled: rgba(255, 255, 255, 1);
  --p-color-text-info: rgba(0, 82, 124, 1);
  --p-color-text-info-hover: rgba(0, 58, 90, 1);
  --p-color-text-info-active: rgba(0, 33, 51, 1);
  --p-color-text-info-on-bg-fill: rgba(0, 33, 51, 1);
  --p-color-text-success: rgba(12, 81, 50, 1);
  --p-color-text-success-hover: rgba(8, 61, 37, 1);
  --p-color-text-success-active: rgba(9, 42, 27, 1);
  --p-color-text-success-on-bg-fill: rgba(248, 255, 251, 1);
  --p-color-text-caution: rgba(79, 71, 0, 1);
  --p-color-text-caution-hover: rgba(51, 46, 0, 1);
  --p-color-text-caution-active: rgba(31, 28, 0, 1);
  --p-color-text-caution-on-bg-fill: rgba(51, 46, 0, 1);
  --p-color-text-warning: rgba(94, 66, 0, 1);
  --p-color-text-warning-hover: rgba(65, 45, 0, 1);
  --p-color-text-warning-active: rgba(37, 26, 0, 1);
  --p-color-text-warning-on-bg-fill: rgba(37, 26, 0, 1);
  --p-color-text-critical: rgba(142, 31, 11, 1);
  --p-color-text-critical-hover: rgba(95, 21, 7, 1);
  --p-color-text-critical-active: rgba(47, 10, 4, 1);
  --p-color-text-critical-on-bg-fill: rgba(255, 251, 251, 1);
  --p-color-text-emphasis: rgba(0, 91, 211, 1);
  --p-color-text-emphasis-hover: rgba(0, 66, 153, 1);
  --p-color-text-emphasis-active: rgba(0, 46, 106, 1);
  --p-color-text-emphasis-on-bg-fill: rgba(252, 253, 255, 1);
  --p-color-text-emphasis-on-bg-fill-hover: rgba(226, 231, 255, 1);
  --p-color-text-emphasis-on-bg-fill-active: rgba(213, 220, 255, 1);
  --p-color-text-magic: rgba(87, 0, 209, 1);
  --p-color-text-magic-secondary: rgba(128, 81, 255, 1);
  --p-color-text-magic-on-bg-fill: rgba(253, 253, 255, 1);
  --p-color-text-inverse: rgba(227, 227, 227, 1);
  --p-color-text-inverse-secondary: rgba(181, 181, 181, 1);
  --p-color-text-link-inverse: rgba(197, 208, 255, 1);
  --p-color-border: rgba(227, 227, 227, 1);
  --p-color-border-hover: rgba(204, 204, 204, 1);
  --p-color-border-disabled: rgba(235, 235, 235, 1);
  --p-color-border-secondary: rgba(235, 235, 235, 1);
  --p-color-border-tertiary: rgba(204, 204, 204, 1);
  --p-color-border-focus: rgba(0, 91, 211, 1);
  --p-color-border-brand: rgba(227, 227, 227, 1);
  --p-color-border-info: rgba(168, 216, 255, 1);
  --p-color-border-success: rgba(146, 254, 194, 1);
  --p-color-border-caution: rgba(255, 235, 120, 1);
  --p-color-border-warning: rgba(255, 200, 121, 1);
  --p-color-border-critical: rgba(254, 195, 193, 1);
  --p-color-border-critical-secondary: rgba(142, 31, 11, 1);
  --p-color-border-emphasis: rgba(0, 91, 211, 1);
  --p-color-border-emphasis-hover: rgba(0, 66, 153, 1);
  --p-color-border-emphasis-active: rgba(0, 46, 106, 1);
  --p-color-border-magic: rgba(228, 222, 255, 1);
  --p-color-border-magic-secondary: rgba(148, 116, 255, 1);
  --p-color-border-magic-secondary-hover: rgba(128, 81, 255, 1);
  --p-color-border-inverse: rgba(97, 97, 97, 1);
  --p-color-border-inverse-hover: rgba(204, 204, 204, 1);
  --p-color-border-inverse-active: rgba(227, 227, 227, 1);
  --p-color-icon: rgba(74, 74, 74, 1);
  --p-color-icon-hover: rgba(48, 48, 48, 1);
  --p-color-icon-active: rgba(26, 26, 26, 1);
  --p-color-icon-disabled: rgba(204, 204, 204, 1);
  --p-color-icon-secondary: rgba(138, 138, 138, 1);
  --p-color-icon-secondary-hover: rgba(97, 97, 97, 1);
  --p-color-icon-secondary-active: rgba(74, 74, 74, 1);
  --p-color-icon-brand: rgba(26, 26, 26, 1);
  --p-color-icon-info: rgba(0, 148, 213, 1);
  --p-color-icon-success: rgba(41, 132, 90, 1);
  --p-color-icon-caution: rgba(153, 138, 0, 1);
  --p-color-icon-warning: rgba(178, 132, 0, 1);
  --p-color-icon-critical: rgba(239, 77, 47, 1);
  --p-color-icon-emphasis: rgba(0, 91, 211, 1);
  --p-color-icon-emphasis-hover: rgba(0, 66, 153, 1);
  --p-color-icon-emphasis-active: rgba(0, 46, 106, 1);
  --p-color-icon-magic: rgba(128, 81, 255, 1);
  --p-color-icon-inverse: rgba(227, 227, 227, 1);
  --p-color-avatar-bg-fill: rgba(181, 181, 181, 1);
  --p-color-avatar-five-bg-fill: rgba(254, 209, 221, 1);
  --p-color-avatar-five-text-on-bg-fill: rgba(141, 4, 72, 1);
  --p-color-avatar-four-bg-fill: rgba(192, 225, 255, 1);
  --p-color-avatar-four-text-on-bg-fill: rgba(0, 82, 124, 1);
  --p-color-avatar-one-bg-fill: rgba(251, 207, 251, 1);
  --p-color-avatar-one-text-on-bg-fill: rgba(121, 26, 121, 1);
  --p-color-avatar-text-on-bg-fill: rgba(255, 255, 255, 1);
  --p-color-avatar-three-bg-fill: rgba(146, 237, 222, 1);
  --p-color-avatar-three-text-on-bg-fill: rgba(9, 83, 70, 1);
  --p-color-avatar-two-bg-fill: rgba(56, 250, 163, 1);
  --p-color-avatar-two-text-on-bg-fill: rgba(12, 81, 50, 1);
  --p-color-backdrop-bg: rgba(0, 0, 0, 0.71);
  --p-color-checkbox-bg-surface-disabled: rgba(0, 0, 0, 0.08);
  --p-color-checkbox-icon-disabled: rgba(255, 255, 255, 1);
  --p-color-input-bg-surface: rgba(253, 253, 253, 1);
  --p-color-input-bg-surface-hover: rgba(250, 250, 250, 1);
  --p-color-input-bg-surface-active: rgba(247, 247, 247, 1);
  --p-color-input-border: rgba(138, 138, 138, 1);
  --p-color-input-border-hover: rgba(97, 97, 97, 1);
  --p-color-input-border-active: rgba(26, 26, 26, 1);
  --p-color-nav-bg: rgba(235, 235, 235, 1);
  --p-color-nav-bg-surface: rgba(0, 0, 0, 0.02);
  --p-color-nav-bg-surface-hover: rgba(241, 241, 241, 1);
  --p-color-nav-bg-surface-active: rgba(250, 250, 250, 1);
  --p-color-nav-bg-surface-selected: rgba(250, 250, 250, 1);
  --p-color-radio-button-bg-surface-disabled: rgba(0, 0, 0, 0.08);
  --p-color-radio-button-icon-disabled: rgba(255, 255, 255, 1);
  --p-color-video-thumbnail-play-button-bg-fill-hover: rgba(0, 0, 0, 0.81);
  --p-color-video-thumbnail-play-button-bg-fill: rgba(0, 0, 0, 0.71);
  --p-color-video-thumbnail-play-button-text-on-bg-fill: rgba(255, 255, 255, 1);
  --p-font-family-sans: "Inter", -apple-system, BlinkMacSystemFont,
    "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --p-font-family-mono: ui-monospace, SFMono-Regular, "SF Mono", Consolas,
    "Liberation Mono", Menlo, monospace;
  --p-font-size-275: 0.6875rem;
  --p-font-size-300: 0.75rem;
  --p-font-size-325: 0.8125rem;
  --p-font-size-350: 0.875rem;
  --p-font-size-400: 1rem;
  --p-font-size-500: 1.25rem;
  --p-font-size-600: 1.5rem;
  --p-font-size-750: 1.875rem;
  --p-font-size-900: 2.25rem;
  --p-font-size-1000: 2.5rem;
  --p-font-weight-regular: 450;
  --p-font-weight-medium: 550;
  --p-font-weight-semibold: 650;
  --p-font-weight-bold: 700;
  --p-font-letter-spacing-densest: -0.03375rem;
  --p-font-letter-spacing-denser: -0.01875rem;
  --p-font-letter-spacing-dense: -0.0125rem;
  --p-font-letter-spacing-normal: 0rem;
  --p-font-line-height-300: 0.75rem;
  --p-font-line-height-400: 1rem;
  --p-font-line-height-500: 1.25rem;
  --p-font-line-height-600: 1.5rem;
  --p-font-line-height-700: 1.75rem;
  --p-font-line-height-800: 2rem;
  --p-font-line-height-1000: 2.5rem;
  --p-font-line-height-1200: 3rem;
  --p-height-0: 0rem;
  --p-height-025: 0.0625rem;
  --p-height-050: 0.125rem;
  --p-height-100: 0.25rem;
  --p-height-150: 0.375rem;
  --p-height-200: 0.5rem;
  --p-height-300: 0.75rem;
  --p-height-400: 1rem;
  --p-height-500: 1.25rem;
  --p-height-600: 1.5rem;
  --p-height-700: 1.75rem;
  --p-height-800: 2rem;
  --p-height-900: 2.25rem;
  --p-height-1000: 2.5rem;
  --p-height-1200: 3rem;
  --p-height-1600: 4rem;
  --p-height-2000: 5rem;
  --p-height-2400: 6rem;
  --p-height-2800: 7rem;
  --p-height-3200: 8rem;
  --p-motion-duration-0: 0ms;
  --p-motion-duration-50: 50ms;
  --p-motion-duration-100: 100ms;
  --p-motion-duration-150: 150ms;
  --p-motion-duration-200: 200ms;
  --p-motion-duration-250: 250ms;
  --p-motion-duration-300: 300ms;
  --p-motion-duration-350: 350ms;
  --p-motion-duration-400: 400ms;
  --p-motion-duration-450: 450ms;
  --p-motion-duration-500: 500ms;
  --p-motion-duration-5000: 5000ms;
  --p-motion-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --p-motion-ease-in: cubic-bezier(0.42, 0, 1, 1);
  --p-motion-ease-out: cubic-bezier(0.19, 0.91, 0.38, 1);
  --p-motion-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
  --p-motion-linear: cubic-bezier(0, 0, 1, 1);
  --p-motion-keyframes-bounce: p-motion-keyframes-bounce;
  --p-motion-keyframes-fade-in: p-motion-keyframes-fade-in;
  --p-motion-keyframes-pulse: p-motion-keyframes-pulse;
  --p-motion-keyframes-spin: p-motion-keyframes-spin;
  --p-motion-keyframes-appear-above: p-motion-keyframes-appear-above;
  --p-motion-keyframes-appear-below: p-motion-keyframes-appear-below;
  --p-shadow-0: none;
  --p-shadow-100: 0rem 0.0625rem 0rem 0rem rgba(26, 26, 26, 0.07);
  --p-shadow-200: 0rem 0.1875rem 0.0625rem -0.0625rem rgba(26, 26, 26, 0.07);
  --p-shadow-300: 0rem 0.25rem 0.375rem -0.125rem rgba(26, 26, 26, 0.2);
  --p-shadow-400: 0rem 0.5rem 1rem -0.25rem rgba(26, 26, 26, 0.22);
  --p-shadow-500: 0rem 0.75rem 1.25rem -0.5rem rgba(26, 26, 26, 0.24);
  --p-shadow-600: 0rem 1.25rem 1.25rem -0.5rem rgba(26, 26, 26, 0.28);
  --p-shadow-bevel-100: 0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset,
    0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset;
  --p-shadow-inset-100: 0rem 0.0625rem 0.125rem 0rem rgba(26, 26, 26, 0.15)
      inset,
    0rem 0.0625rem 0.0625rem 0rem rgba(26, 26, 26, 0.15) inset;
  --p-shadow-inset-200: 0rem 0.125rem 0.0625rem 0rem rgba(26, 26, 26, 0.2) inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.12) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.12) inset;
  --p-shadow-button: 0rem 0.0625rem 0rem 0rem #e3e3e3 inset,
    0.0625rem 0rem 0rem 0rem #e3e3e3 inset,
    -0.0625rem 0rem 0rem 0rem #e3e3e3 inset,
    0rem -0.0625rem 0rem 0rem #b5b5b5 inset;
  --p-shadow-button-hover: 0rem 0.0625rem 0rem 0rem #ebebeb inset,
    -0.0625rem 0rem 0rem 0rem #ebebeb inset,
    0.0625rem 0rem 0rem 0rem #ebebeb inset, 0rem -0.0625rem 0rem 0rem #ccc inset;
  --p-shadow-button-inset: 0rem 0.125rem 0.0625rem 0rem rgba(26, 26, 26, 0.2)
      inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.12) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.12) inset;
  --p-shadow-button-primary: 0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2)
      inset,
    0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.0625rem 0rem 0.0625rem #000 inset,
    0rem 0.0625rem 0rem 0rem #000 inset;
  --p-shadow-button-primary-hover: 0rem 0.0625rem 0rem 0rem
      rgba(255, 255, 255, 0.24) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.0625rem 0rem 0rem #000 inset, 0rem -0.0625rem 0rem 0.0625rem #1a1a1a;
  --p-shadow-button-primary-inset: 0rem 0.1875rem 0rem 0rem #000 inset;
  --p-shadow-button-primary-critical: 0rem 0.0625rem 0rem 0rem
      rgba(255, 255, 255, 0.48) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset;
  --p-shadow-button-primary-critical-hover: 0rem 0.0625rem 0rem 0rem
      rgba(255, 255, 255, 0.48) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset;
  --p-shadow-button-primary-critical-inset: 0rem 0.125rem 0rem 0rem
      rgba(0, 0, 0, 0.6) inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset;
  --p-shadow-button-primary-success: 0rem 0.0625rem 0rem 0rem
      rgba(255, 255, 255, 0.48) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset;
  --p-shadow-button-primary-success-hover: 0rem 0.0625rem 0rem 0rem
      rgba(255, 255, 255, 0.48) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset;
  --p-shadow-button-primary-success-inset: 0rem 0.125rem 0rem 0rem
      rgba(0, 0, 0, 0.6) inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset;
  --p-shadow-border-inset: 0rem 0rem 0rem 0.0625rem rgba(0, 0, 0, 0.08) inset;
  --p-space-0: 0rem;
  --p-space-025: 0.0625rem;
  --p-space-050: 0.125rem;
  --p-space-100: 0.25rem;
  --p-space-150: 0.375rem;
  --p-space-200: 0.5rem;
  --p-space-300: 0.75rem;
  --p-space-400: 1rem;
  --p-space-500: 1.25rem;
  --p-space-600: 1.5rem;
  --p-space-800: 2rem;
  --p-space-1000: 2.5rem;
  --p-space-1200: 3rem;
  --p-space-1600: 4rem;
  --p-space-2000: 5rem;
  --p-space-2400: 6rem;
  --p-space-2800: 7rem;
  --p-space-3200: 8rem;
  --p-space-button-group-gap: var(--p-space-200);
  --p-space-card-gap: var(--p-space-400);
  --p-space-card-padding: var(--p-space-400);
  --p-space-table-cell-padding: var(--p-space-150);
  --p-text-heading-3xl-font-family: var(--p-font-family-sans);
  --p-text-heading-3xl-font-size: var(--p-font-size-900);
  --p-text-heading-3xl-font-weight: var(--p-font-weight-bold);
  --p-text-heading-3xl-font-letter-spacing: var(
    --p-font-letter-spacing-densest
  );
  --p-text-heading-3xl-font-line-height: var(--p-font-line-height-1200);
  --p-text-heading-2xl-font-family: var(--p-font-family-sans);
  --p-text-heading-2xl-font-size: var(--p-font-size-750);
  --p-text-heading-2xl-font-weight: var(--p-font-weight-bold);
  --p-text-heading-2xl-font-letter-spacing: var(--p-font-letter-spacing-denser);
  --p-text-heading-2xl-font-line-height: var(--p-font-line-height-1000);
  --p-text-heading-xl-font-family: var(--p-font-family-sans);
  --p-text-heading-xl-font-size: var(--p-font-size-600);
  --p-text-heading-xl-font-weight: var(--p-font-weight-bold);
  --p-text-heading-xl-font-letter-spacing: var(--p-font-letter-spacing-dense);
  --p-text-heading-xl-font-line-height: var(--p-font-line-height-800);
  --p-text-heading-lg-font-family: var(--p-font-family-sans);
  --p-text-heading-lg-font-size: var(--p-font-size-500);
  --p-text-heading-lg-font-weight: var(--p-font-weight-semibold);
  --p-text-heading-lg-font-letter-spacing: var(--p-font-letter-spacing-dense);
  --p-text-heading-lg-font-line-height: var(--p-font-line-height-600);
  --p-text-heading-md-font-family: var(--p-font-family-sans);
  --p-text-heading-md-font-size: var(--p-font-size-350);
  --p-text-heading-md-font-weight: var(--p-font-weight-semibold);
  --p-text-heading-md-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-heading-md-font-line-height: var(--p-font-line-height-500);
  --p-text-heading-sm-font-family: var(--p-font-family-sans);
  --p-text-heading-sm-font-size: var(--p-font-size-325);
  --p-text-heading-sm-font-weight: var(--p-font-weight-semibold);
  --p-text-heading-sm-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-heading-sm-font-line-height: var(--p-font-line-height-500);
  --p-text-body-lg-font-family: var(--p-font-family-sans);
  --p-text-body-lg-font-size: var(--p-font-size-350);
  --p-text-body-lg-font-weight: var(--p-font-weight-regular);
  --p-text-body-lg-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-body-lg-font-line-height: var(--p-font-line-height-500);
  --p-text-body-md-font-family: var(--p-font-family-sans);
  --p-text-body-md-font-size: var(--p-font-size-325);
  --p-text-body-md-font-weight: var(--p-font-weight-regular);
  --p-text-body-md-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-body-md-font-line-height: var(--p-font-line-height-500);
  --p-text-body-sm-font-family: var(--p-font-family-sans);
  --p-text-body-sm-font-size: var(--p-font-size-300);
  --p-text-body-sm-font-weight: var(--p-font-weight-regular);
  --p-text-body-sm-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-body-sm-font-line-height: var(--p-font-line-height-400);
  --p-text-body-xs-font-family: var(--p-font-family-sans);
  --p-text-body-xs-font-size: var(--p-font-size-275);
  --p-text-body-xs-font-weight: var(--p-font-weight-regular);
  --p-text-body-xs-font-letter-spacing: var(--p-font-letter-spacing-normal);
  --p-text-body-xs-font-line-height: var(--p-font-line-height-300);
  --p-width-0: 0rem;
  --p-width-025: 0.0625rem;
  --p-width-050: 0.125rem;
  --p-width-100: 0.25rem;
  --p-width-150: 0.375rem;
  --p-width-200: 0.5rem;
  --p-width-300: 0.75rem;
  --p-width-400: 1rem;
  --p-width-500: 1.25rem;
  --p-width-600: 1.5rem;
  --p-width-700: 1.75rem;
  --p-width-800: 2rem;
  --p-width-900: 2.25rem;
  --p-width-1000: 2.5rem;
  --p-width-1200: 3rem;
  --p-width-1600: 4rem;
  --p-width-2000: 5rem;
  --p-width-2400: 6rem;
  --p-width-2800: 7rem;
  --p-width-3200: 8rem;
  --p-z-index-1: 100;
  --p-z-index-2: 400;
  --p-z-index-3: 510;
  --p-z-index-4: 512;
  --p-z-index-5: 513;
  --p-z-index-6: 514;
  --p-z-index-7: 515;
  --p-z-index-8: 516;
  --p-z-index-9: 517;
  --p-z-index-10: 518;
  --p-z-index-11: 519;
  --p-z-index-12: 520;

  /* Custom */

  --base-spacing-unit: 0.5rem;

  --spacing-1: calc(
    var(--base-spacing-unit)
  );

  --spacing-2: calc(
    var(--base-spacing-unit) * 2
  );

  --spacing-3: calc(
    var(--base-spacing-unit) * 3
  );

  --spacing-4: calc(
    var(--base-spacing-unit) * 4
  );

  --spacing-5: calc(
    var(--base-spacing-unit) * 5
  );

  @keyframes p-motion-keyframes-bounce {
    from,
    65%,
    85% {
      transform: scale(1);
    }
    75% {
      transform: scale(0.85);
    }
    82.5% {
      transform: scale(1.05);
    }
  }

  @keyframes p-motion-keyframes-fade-in {
    to {
      opacity: 1;
    }
  }
  @keyframes p-motion-keyframes-pulse {
    from,
    75% {
      transform: scale(0.85);
      opacity: 1;
    }
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }
  @keyframes p-motion-keyframes-spin {
    to {
      transform: rotate(1turn);
    }
  }
  @keyframes p-motion-keyframes-appear-above {
    from {
      transform: translateY(var(--p-space-100));
      opacity: 0;
    }
    to {
      transform: none;
      opacity: 1;
    }
  }
  @keyframes p-motion-keyframes-appear-below {
    from {
      transform: translateY(calc(var(--p-space-100) * -1));
      opacity: 0;
    }
    to {
      transform: none;
      opacity: 1;
    }
  }
}

/* --------------- PADDING --------------- */
.p-auto { padding: auto !important; }
.p-1 { padding: var(--spacing-1) !important; }
.p-2 { padding: var(--spacing-2) !important; }
.p-3 { padding: var(--spacing-3) !important; }
.p-4 { padding: var(--spacing-4) !important; }
.p-5 { padding: var(--spacing-5) !important; }

.pt-auto { padding: auto !important; }
.pt-1 { padding-top: var(--spacing-1) !important; }
.pt-2 { padding-top: var(--spacing-2) !important; }
.pt-3 { padding-top: var(--spacing-3) !important; }
.pt-4 { padding-top: var(--spacing-4) !important; }
.pt-5 { padding-top: var(--spacing-5) !important; }

.pr-auto { padding-right: auto !important; }
.pr-1 { padding-right: var(--spacing-1) !important; }
.pr-2 { padding-right: var(--spacing-2) !important; }
.pr-3 { padding-right: var(--spacing-3) !important; }
.pr-4 { padding-right: var(--spacing-4) !important; }
.pr-5 { padding-right: var(--spacing-5) !important; }

.pb-auto { padding-bottom: auto !important; }
.pb-1 { padding-bottom: var(--spacing-1) !important;}
.pb-2 { padding-bottom: var(--spacing-2) !important;}
.pb-3 { padding-bottom: var(--spacing-3) !important;}
.pb-4 { padding-bottom: var(--spacing-4) !important;}
.pb-5 { padding-bottom: var(--spacing-5) !important;}

.pl-auto { padding-left: auto !important; }
.pl-1 { padding-left: var(--spacing-1) !important;}
.pl-2 { padding-left: var(--spacing-2) !important;}
.pl-3 { padding-left: var(--spacing-3) !important;}
.pl-4 { padding-left: var(--spacing-4) !important;}
.pl-5 { padding-left: var(--spacing-5) !important;} 

.px-auto { padding-left: auto !important; padding-right: auto !important;}
.px-1 { padding-left: var(--spacing-1) !important; padding-right: var(--spacing-1) !important;}
.px-2 { padding-left: var(--spacing-2) !important; padding-right: var(--spacing-2) !important;}
.px-3 { padding-left: var(--spacing-3) !important; padding-right: var(--spacing-3) !important;}
.px-4 { padding-left: var(--spacing-4) !important; padding-right: var(--spacing-4) !important;}
.px-5 { padding-left: var(--spacing-5) !important; padding-right: var(--spacing-5) !important;}

.py-auto { padding-top: auto !important; padding-bottom: auto !important;}
.py-1 { padding-top: var(--spacing-1) !important; padding-bottom: var(--spacing-1) !important;}
.py-2 { padding-top: var(--spacing-2) !important; padding-bottom: var(--spacing-2) !important;}
.py-3 { padding-top: var(--spacing-3) !important; padding-bottom: var(--spacing-3) !important;}
.py-4 { padding-top: var(--spacing-4) !important; padding-bottom: var(--spacing-4) !important;}
.py-5 { padding-top: var(--spacing-5) !important; padding-bottom: var(--spacing-5) !important;}

/* --------------- MARGIN --------------- */
.m-auto { margin: auto !important; }
.m-1 { margin: var(--spacing-1) !important; }
.m-2 { margin: var(--spacing-2) !important; }
.m-3 { margin: var(--spacing-3) !important; }
.m-4 { margin: var(--spacing-4) !important; }
.m-5 { margin: var(--spacing-5) !important; }

.mt-auto { margin: auto !important; }
.mt-1 { margin-top: var(--spacing-1) !important; }
.mt-2 { margin-top: var(--spacing-2) !important; }
.mt-3 { margin-top: var(--spacing-3) !important; }
.mt-4 { margin-top: var(--spacing-4) !important; }
.mt-5 { margin-top: var(--spacing-5) !important; }

.mr-auto { margin-right: auto !important; }
.mr-1 { margin-right: var(--spacing-1) !important; }
.mr-2 { margin-right: var(--spacing-2) !important; }
.mr-3 { margin-right: var(--spacing-3) !important; }
.mr-4 { margin-right: var(--spacing-4) !important; }
.mr-5 { margin-right: var(--spacing-5) !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-1 { margin-bottom: var(--spacing-1) !important;}
.mb-2 { margin-bottom: var(--spacing-2) !important;}
.mb-3 { margin-bottom: var(--spacing-3) !important;}
.mb-4 { margin-bottom: var(--spacing-4) !important;}
.mb-5 { margin-bottom: var(--spacing-5) !important;}

.ml-auto { margin-left: auto !important; }
.ml-1 { margin-left: var(--spacing-1) !important;}
.ml-2 { margin-left: var(--spacing-2) !important;}
.ml-3 { margin-left: var(--spacing-3) !important;}
.ml-4 { margin-left: var(--spacing-4) !important;}
.ml-5 { margin-left: var(--spacing-5) !important;} 

.mx-auto { margin-left: auto !important; margin-right: auto !important;}
.mx-1 { margin-left: var(--spacing-1) !important; margin-right: var(--spacing-1) !important;}
.mx-2 { margin-left: var(--spacing-2) !important; margin-right: var(--spacing-2) !important;}
.mx-3 { margin-left: var(--spacing-3) !important; margin-right: var(--spacing-3) !important;}
.mx-4 { margin-left: var(--spacing-4) !important; margin-right: var(--spacing-4) !important;}
.mx-5 { margin-left: var(--spacing-5) !important; margin-right: var(--spacing-5) !important;}

.my-auto { margin-top: auto !important; margin-bottom: auto !important;}
.my-1 { margin-top: var(--spacing-1) !important; margin-bottom: var(--spacing-1) !important;}
.my-2 { margin-top: var(--spacing-2) !important; margin-bottom: var(--spacing-2) !important;}
.my-3 { margin-top: var(--spacing-3) !important; margin-bottom: var(--spacing-3) !important;}
.my-4 { margin-top: var(--spacing-4) !important; margin-bottom: var(--spacing-4) !important;}
.my-5 { margin-top: var(--spacing-5) !important; margin-bottom: var(--spacing-5) !important;}

.Polaris-Icon {
margin: 0 !important;
}

.Polaris-Frame {
  min-height: 0 !important;
}
