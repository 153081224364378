.carousel {
  max-width: 5000px;
  width: 100%;
  overflow: hidden;
  height: 90%;
  padding: 1px;
}

/* .carousel::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
} */

.carousel ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  list-style-type: none;
  /* justify-content: center; */
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

/* .carousel ul.even {
  transform: translateX(-250px);
} */

.carousel-item {
  width: 200px;
  height: 179px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
    0px 2px 1px 0px rgba(0, 0, 0, 0.05);
  padding: 15px 19px;
}

.carousel a {
  position: relative;
  overflow: hidden;
  display: block;
}

/* .carousel .meta {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 20px;
  left: 20px;
  font-weight: bold;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(75px);
  opacity: 0;
} */

/* .carousel ul li.active .meta {
  transform: translateY(0);
  opacity: 1;
} */

.carousel .cta {
  background: #fff;
  padding: 10px 15px;
  display: inline-block;
  color: #222;
  border-radius: 5px;
}

.carousel .caption {
  color: #fff;
  text-shadow: 0 0 5px #000;
}

.carousel .arrows {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  user-select: none;
}

.carousel .arrows span {
  cursor: pointer;
  border: solid #fff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
}

.carousel .arrows span.disabled {
  border-color: #555;
  cursor: default;
}

.carousel .arrows .left {
  transform: rotate(135deg);
}

.carousel .arrows .right {
  transform: rotate(-45deg);
}

.carousel-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 36px;
}

.carousel-item_header {
  display: flex;
  justify-content: space-between;
}

.carousel-item_title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.carousel-item_des {
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.carousel-item_header__button #primary {
  margin-right: 5px;
  background-color: rgb(0, 0, 0);
  color: #fff5ea;
}

.carouser-action .Polaris-Button--outline {
  border: none !important;
}

@media screen and (max-width: 1200px) {
  .carousel-item {
    width: 33rem;
    height: 179px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
      0px 2px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 19px;
  }
  .carousel-item_des {
    margin-top: 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .carousel-item {
    width: 31rem;
    height: 179px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
      0px 2px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 19px;
  }
  .carousel-item_des {
    margin-top: 0px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .carousel-item {
    width: 44rem;
    height: 179px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
      0px 2px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 19px;
  }
}
