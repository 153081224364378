.step1-img {
    overflow: hidden;
    border: 1px solid var(--p-color-border);
}

.step1-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.select-api-btn-left {
    border-radius: 4px  0px  0px  4px;
}

.select-api-btn-right {
    border-radius: 0px  4px  4px  0px;
}

.select-api-btn {
    border: 1px solid var(--p-color-border);
    padding: 8px  16px;
}

.select-api-btn.selected {
    background: #000;
}

.copy-script-box {
    position: relative;
    height: 80px;
    background: var(--p-color-bg-surface-secondary);
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.copy-script-box .copy-script-btn {
    position: absolute;
    top: 12px;
    right: 12px;
}

.target-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.target-selected .target-selected-item  {
    border-radius: 8px;
    background: #cdcdcd;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.target-selected-item svg {
    cursor: pointer;
}