.navigation {
    list-style: none;
    display: flex;
    margin-bottom: 12px;
    border-bottom: 1px solid #cdcdcd;
    padding: 0;
    margin-top: 0;
}

.navigation li {
    position: relative;
    display: block;
    padding: 0 24px 12px 24px;
    z-index: 1;
    margin-left: 4px;
    margin-left: 0;
    overflow: hidden;
    cursor: pointer;
}

.navigation li a {
    position: relative;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
}

.navigation li.active a,
.navigation li:hover a {
    color: rgba(0, 0, 0, 1)
}

.navigation li:hover::after,
.navigation li.active::after {
    position: absolute;
    content: '';
    bottom: -3px;
    left: 50%;
    width: 90%;
    height: 6px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    transform: translateX(-50%);
}

.navigation li.active::after {
    background: rgba(0, 0, 0, 1);
}
