.refresh-data-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--p-color-border);
    outline: none;
    border-radius: 8px;
    height: 32px;
    background: var(--p-color-bg-fill);
    padding: 0 8px;
    cursor: pointer
}

.dashboard .Polaris-Grid {
    grid-template-rows: auto 360px 320px auto;
}

.dashboard .Polaris-Grid .Polaris-ShadowBevel {
    height: 100%;
}

.dashboard .Polaris-Grid .Polaris-ShadowBevel .Polaris-Box {
    height: 100%;
}

.dashboard .Polaris-SkeletonDisplayText__DisplayText {
    width: 240px;
}

.loading-block .Polaris-ShadowBevel {
    height: 100%;
}
