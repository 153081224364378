.insight-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.insight {
  display: block;
  text-decoration: none;
  color: var(--p-color-text);
  border-radius: 4px;
  border-bottom: 1px solid var(--p-color-border);
  background: transparent;
  transition: all .5s;
}

.insight:hover {
  background: var(--p-color-bg-fill-transparent-hover);
}

.insight-posts {
  overflow-y: scroll;
}

.insight-posts::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.insight-posts::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.insight-posts::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.insight-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  margin-top: auto;
  background: #fff;
}