.feedback {
    height: 100%;
}

.feedback-btns {
    background: #fdf5f2;
}

.feedback-thank {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    z-index: 2;
    padding: 56px;
    padding-top: 78px;
}

.feedback-thank::after {
    content: url('../../assets/img/thank_you.png');
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 100000;
    transform: translateY(-50%) translateX(-50%);
}

.feedback-thank::before {
    content: '';
    position: absolute;
    background: #fff;
    top: -16px;
    right: -16px;
    width: 20%;
    height: 20%;
    z-index: 100000;
    border-radius: 16px;
}

.Polaris-Modal-Dialog__Modal {
    overflow: visible;
}

.Polaris-Modal-Dialog__Modal > .Polaris-Box {
    overflow: visible !important;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
