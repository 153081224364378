.progress {
    border-bottom: 1px solid var(--p-color-border);
}

.progress-step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.progress-status {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
}
