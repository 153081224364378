.manage-pixel {
  max-width: 1280px;
  margin: 0 auto;
}

.manage-pixel .Polaris-IndexTable__TableHeading {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  background: inherit;
}

.manage-pixel .Polaris-IndexTable__Table {
  border-collapse: inherit;
}

.eventapi-badge{
  color: #fff;
  background: var(--p-color-bg-fill-success);
  border-radius: 8px;
  padding: 2px 6px;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.eventapi-badge svg{
  fill: #fff
}

.browserpixel-badge{
  color: #000;
  background: var(--p-color-bg-fill-warning);
  border-radius: 8px;
  padding: 2px 6px;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.managepixel-table {
  border-radius: 8px;
  background: #fff;
}

.loading-manage-pixel {
  height: 276px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manage-pixel-banner{
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.manage-pixel-banner img{
  width: 100%;
}