

.toturial-vid-container {
    height: 100%;
}

.toturial-vid {
    padding: 0 8px;
    height: 100%;
}

.toturial-vid .Polaris-LegacyCard {
 height: 100%;
}

.toturial .carousel .thumbs-wrapper {
    margin: 0
}

.toturial .carousel ul {
    gap: 0
}

.toturial .Polaris-MediaCard__Description {
    text-align: left;
}
